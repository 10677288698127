define("discourse/plugins/delete-topic-ui/discourse/controllers/admin-plugins-delete-topic-ui", ["exports", "discourse/lib/ajax", "discourse-common/utils/decorators", "discourse/lib/load-script", "@ember/object/computed", "rsvp", "discourse-common/config/environment", "@ember/template"], function (_exports, _ajax, _decorators, _loadScript, _computed, _rsvp, _environment, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = Ember.Controller.extend({
    actions: (_dec = (0, _decorators.default)("this.siteSettings.delete_posts_for_username"), (_obj = {
      /* deletePosts() {
        let responseElement = document.querySelector("p.response.notice");
        responseElement.innerHTML = "Deleting all posts for user";
        ajax('/admin/plugins/delete_all_posts', {
          type: 'GET',
          data: {
            // Include any data you want to send to the server in the request body
            // Example: param1: 'value1', param2: 'value2'
          },
          dataType: 'text'
        })
          .then(response => {
            // Handle the success response
            let response_element = document.querySelector("p.response.notice");
            response_element.innerHTML= response;
            console.log(response);
             if(response_element.classList.contains("hide")){
              response_element.classList.remove('hide');
            setTimeout(function(){
              response_element.classList.add("hide");
            },10000);
             }
           })
          .catch(error => {
            // Handle the error
            let response_element = document.querySelector("p.response.notice");
            response_element.innerHTML= "Error deleting posts something unusual happned at server side for more information check console and error log file";
            console.error(error);
             if(response_element.classList.contains("hide")){
              response_element.classList.remove('hide');
            setTimeout(function(){
              response_element.classList.add("hide");
            },10000);
             }
          });
      }, */
      saveSettings() {
        const deletePostsForUsername = document.getElementById('delete_posts_for_username').value;
        const deletePostsInSingleBatch = document.getElementById('delete_posts_in_single_batch').value;
        const deleteUserTopicsEnabled = document.getElementById('delete_user_topics_enabled').checked;
        const deleteUserTopicsDryRun = document.getElementById('delete_user_topics_dry_run').checked;
        const data = {
          delete_posts_for_username: deletePostsForUsername,
          delete_posts_in_single_batch: deletePostsInSingleBatch,
          delete_user_topics_enabled: deleteUserTopicsEnabled,
          delete_user_topics_dry_run: deleteUserTopicsDryRun
        };

        // Perform the necessary logic to save the updated settings, e.g., via AJAX request or other method
        (0, _ajax.ajax)('/admin/plugins/save_settings', {
          type: 'GET',
          data: {
            settings: data
          }
        }).then(response => {
          // Handle the success response
          let response_element = document.querySelector("p.response.notice");
          response_element.innerHTML = response;
          if (response_element.classList.contains("hide")) {
            response_element.classList.remove('hide');
            setTimeout(function () {
              response_element.classList.add("hide");
            }, 20000);
          }
          console.log('Settings saved:', response);
          // Optionally display a success message to the user
        }).catch(error => {
          // Handle the error response
          let response_element = document.querySelector("p.response.notice");
          response_element.innerHTML = error.responseText;
          console.error('Error saving settings:', error);
          if (response_element.classList.contains("hide")) {
            response_element.classList.remove('hide');
            setTimeout(function () {
              response_element.classList.add("hide");
            }, 10000);
          }
          // Optionally display an error message to the user
        });
        // Prevent the form from submitting
        return false;
      },
      userName() {
        return this.siteSettings.delete_posts_for_username;
      }
    }, (_applyDecoratedDescriptor(_obj, "userName", [_dec], Object.getOwnPropertyDescriptor(_obj, "userName"), _obj)), _obj))
  });
});